<template>
  <a-space>
    <a-button type="primary" @click="confirmToEffective">生效</a-button>
    <a-button type="primary" @click="confirmToIneffective">失效</a-button>
  </a-space>
</template>

<script>
import { batchUpdateAgencyAgentsProductsEffectiveByTopAgent } from '@/api/agents_product'

export default {
  name: 'UpdateAgentsProductEffective',
  props: {
    selectedRowKeys: {
      type: Array,
      required: true
    },
    agentId: {
      type: Number,
      required: true
    }
  },
  methods: {
    warningSelectObjects() {
      this.$warning({
        title: '请选择操作对象',
        content: ''
      })
    },

    confirmToEffective() {
      const vm = this
      const ids = vm.selectedRowKeys
      if (ids.length === 0) {
        this.warningSelectObjects()
      } else {
        this.$confirm({
          title: '确定生效吗?',
          content: '',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
            batchUpdateAgencyAgentsProductsEffectiveByTopAgent({ ids: ids, effective: true, agent_id: vm.agentId }).then((res) => {
              // 存在部分成功情况，都返回 completed
              vm.$emit('completed')
            })
          }
        })
      }
    },

    confirmToIneffective() {
      const vm = this
      const ids = vm.selectedRowKeys
      if (ids.length === 0) {
        this.warningSelectObjects()
      } else {
        this.$confirm({
          title: '确定失效吗?',
          content: '',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
            batchUpdateAgencyAgentsProductsEffectiveByTopAgent({ ids: ids, effective: false, agent_id: vm.agentId }).then((res) => {
              // 存在部分成功情况，告知父组件已完成
              vm.$emit('completed')
            })
          }
        })
      }
    }
  }
}
</script>
